@font-face {
    font-family: "Pathway Gothic One";
    src: local("Pathway Gothic One"), url(../fonts/PathwayGothicOne-Regular.ttf);
  }
@font-face {
    font-family: "Open Sans Condensed";
    src: local("Open Sans Condensed"), url(../fonts/OpenSansCondensed-Light.ttf);
  }
@import "./theme.scss";
// https://eduardoboucas.github.io/include-media/
@import "~include-media/dist/_include-media.scss";
// https://create-react-app.dev/docs/adding-bootstrap
@import "~bootstrap/scss/bootstrap";

/* General
************************************************************** */
* {
    font-family: "Pathway Gothic One";
}
body {
  background-color: black;
}
h2 {
  font-family: "Pathway Gothic One";
  font-size: 4rem;
}
a:hover {
  text-decoration: none;
  cursor: pointer; 
}
hr {
  border: 0.1rem solid orange;
  background-color: orange;
  margin: 2rem auto 3rem auto;
}
.scroll,
.social-links {
  font-size: 2.5rem;
  margin-top: 5px;
}
#error {
  color: orange;
}

/* Theme
************************************************************** */
.dark {
  @include darkTheme();
}
.light {
  @include lightTheme();
}

/* Animations
************************************************************** */
.loading {
  position: relative;
}
.loading::before {
  content: "Loading...";
  position: absolute;
  color: orange;
  overflow: hidden;
  border-right: 4px solid orange;
  animation: loading 5s linear infinite;
}
@keyframes loading {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.logo {
  animation: spin infinite 20s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.card:hover {
  border-color: orangered !important;
}

/* About
************************************************************** */
#about {
  h1 {
    margin-top: 4rem;
  }
  button {
    background-color: orange;
  }
  .nav-item {
    text-transform: uppercase;
    :hover {
      color: orange !important;
      cursor: default;
      transition: color .3s;
    }
  }
  .logo {
    height: 33vmin;
    pointer-events: none;
  }
  h1 {
    font-family: "Pathway Gothic One";
    font-size: 5rem;
    text-transform: uppercase;
  }
  p {
    font-size: 1.5rem;
    }
  p:hover {
      font-weight: bold !important;
    }
  .scroll {
    color: orange;
    #scroll-down {
      margin-top: 5rem;
    }
  }
}

/* Skills
************************************************************** */
#skills {
    h2 {
      margin-top: 4rem;
      }
  figure {
    margin-top: 2.5rem;
    figcaption {
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }
  }
}

/* Projects
************************************************************** */
#projects {
  h2 {
    margin-top: 4rem;
    }
  .card {
    background-color: transparent;
    border: 2px solid orange;
    img {
      margin-top: 2rem;
      max-height: 15rem;
      object-fit: contain;
    }
    .card-title {
      color: inherit;
      font-weight: 500;
    }
    .card-title:hover {
      color: orange;
      text-shadow: .1px .1px .2px orangered;
    }
  }
}

/* Contact
************************************************************** */
#contact {
  h2 {
    margin-top: 4rem;
  }
  img,
  h3 {
    margin-bottom: 2.2rem;
    margin-top: 1rem;
  }
  min-height: 90vh;
  img {
    border: 0.2rem solid orange;
    border-radius: 50%;
    width: 60%;
    height: auto;
  }
  span {
    color: orange;
  }
  div.contact-info-container {
    display: inline-block !important;
  }
  .email, .gitHub {
    color: darkslategrey;
    font-size: 15pt;
  }

  .email:hover, .gitHub:hover {
    text-shadow: .1px .1px 1px yellow;
  }
  .scroll {
    color: orange;
    #scroll-up {
        margin: 5rem 0 1rem 0;
    }
  }
}

/* Footer
************************************************************** */
footer {
  background-color: inherit;
  a {
    color: white !important;
    margin: 0px -5px;
    padding: 12px;
    :hover {
        color: orange !important;
        cursor: default;
        transition: color .3s;
      }
  }
  p {
    color: white;
    font-family: 'Open Sans Condensed';
    font-weight: semi-bold;
    margin-top: .25rem;
  }
  span {
    color: red;
  }
  #myInfo {
    color: orange;
  }
}

/* Media Queries
************************************************************** */
// iPhones
@include media(">=phone", "<tablet") {
  h2 {
    font-size: 2.75rem;
  }
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  .scroll,
  .social-links {
    font-size: 2rem;
  }
  .app-error {
    h1 {
      font-size: 1.5rem;
    }
  }
  #about {
    h1 {
      font-size: 2.75rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 2.5rem;
      }
    }
  }
  #skills {
    figure {
      margin-top: 0.75rem;
      figcaption {
        margin-top: 0.25rem;
        font-size: 1rem;
      }
    }
  }
  #projects {
    h2 {
      margin-top: 4.25rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.25rem;
    }
    img {
      width: 45%;
    }
    .scroll {
      #scroll-up {
        margin-top: 3rem;
      }
    }
  }
}
// iPhones landscape
@include media(">=phone", "<tablet", "landscape") {
  #about {
    .logo {
      display: none;
    }
    h1 {
      margin-top: 2.5rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 0.5rem;
      }
    }
  }
  #skills {
    h2 {
      margin-top: 4.75rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.8rem;
    }
    img {
      width: 32%;
      margin-top: 0;
    }
    .scroll {
      #scroll-up {
        margin: 2rem 0 2rem 0;
      }
    }
  }
}
// iPad
@include media(">=tablet", "<desktop") {
  #about {
    h1 {
      font-size: 4rem;
    }
  }
  #contact {
    img {
      width: 35%;
    }
    .scroll {
      #scroll-up {
        margin-top: 6rem;
      }
    }
  }
}
// iPhone X landscape
@include media(">=tablet", "<desktop", "landscape") {
  h2 {
    font-size: 2.75rem;
  }
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  .scroll,
  .social-links {
    font-size: 2rem;
  }
  .app-error {
    h1 {
      font-size: 1.5rem;
    }
  }
  #about {
    .logo {
      display: none;
    }
    h1 {
      font-size: 2.75rem;
      margin-top: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 2.5rem;
      }
    }
  }
  #skills {
    h2 {
      margin-top: 4.75rem;
    }
    figure {
      margin-top: 0.75rem;
      figcaption {
        margin-top: 0.25rem;
        font-size: 1rem;
      }
    }
  }
  #projects {
    h2 {
      margin-top: 4.25rem;
    }
  }
  #contact {
    h2 {
      margin-top: 3.5rem;
    }
    img {
      width: 33%;
      margin-top: 0;
    }
    .scroll {
      #scroll-up {
        margin: 2rem 0 2rem 0;
      }
    }
  }
}
// iPad Landscape
@include media(">tablet", "<=desktop", "landscape") {
  hr {
    margin: 0.5rem auto 0.5rem auto;
  }
  #about {
    h1 {
      font-size: 4rem;
    }
    .scroll {
      #scroll-down {
        margin-top: 1rem;
      }
    }
  }
  #contact {
    .scroll {
      #scroll-up {
        margin-top: 5rem;
      }
    }
  }
}
