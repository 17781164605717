/* Dark theme
************************************************************** */
@mixin darkTheme() {
  background-color: black;
  color: white;
  // Start - Dark/Light mode toggle
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: black !important;
    box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
  }
  .custom-control-input ~ .custom-control-label::before {
    background-color: black !important;
    border-color: black !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: black !important;
    border-color: black !important;
  }
  // End
  a {
    color: white;
  }
  p:hover {
      color: lightgreen !important;
      text-shadow: purple .5px .5px 3px !important;
      font-weight: bold !important;
    }
}

/* Light theme
************************************************************** */
@mixin lightTheme() {
  background-color: whitesmoke;
  color: darkslategray;
  text-shadow: .1px .1px .5px seagreen;
  // Start - Dark/Light mode toggle
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: black !important;
    box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
  }
  .custom-control-input ~ .custom-control-label::before {
    background-color: orange !important;
    border-color: orange !important;
  }
  // End
  a {
    color: black;
    text-shadow: .1px .1px .5px seagreen;
  }
  .navbar {
      background-color: orangered !important;
      a:hover {
          text-shadow: .1px .1px 2px red !important;
      }
  }
  .card-title {
    color: #f7f7f7;
  }
}
